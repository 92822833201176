import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Components
import { SnackBarComponent } from "src/app/modules/shared/components/snack-bar/snack-bar.component";
import { HeaderComponent } from "src/app/modules/shared/components/header/header.component";

// Modules
import { MaterialModule } from "src/app/modules/material/material.module";

@NgModule({
  declarations: [HeaderComponent, SnackBarComponent],
  imports: [CommonModule, MaterialModule],
  exports: [HeaderComponent]
})
export class SharedModule {}
