import { createAction, props } from "@ngrx/store";

// Models
import { RawLoginCredentials } from "src/app/modules/auth/models/login-credentials.model";

export enum AuthActionTypes {
  Login = "[Login Page] Login",
  LoginSuccess = "[Auth API] Login Success",
  LoginFailed = "[Auth API] Login Failed",
  
  Logout = "[Logout Dialog] Logout Started",
  LogoutCancel = "[Auth API] Logout Cancel",
  LogoutSuccess = "[Auth API] Logout Success",
}

// Login
export const login = createAction(AuthActionTypes.Login, props<{ credentials: RawLoginCredentials }>());
export const loginSuccess = createAction(
  AuthActionTypes.LoginSuccess,
  props<{ username: string; isLoginSuccess: boolean }>()
);
export const loginFailed = createAction(AuthActionTypes.LoginFailed, props<{ errorMessage: string }>());

// Logout
export const logout = createAction(AuthActionTypes.Logout);
export const logoutSuccess = createAction(AuthActionTypes.LogoutSuccess);
export const logoutCancel = createAction(AuthActionTypes.LogoutCancel);
