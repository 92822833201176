import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Component
import { LoginComponent } from "src/app/modules/auth/components/login/login.component";

// Effects
import { AuthEffects } from "src/app/modules/auth/store/auth.effects";

// Module
import { MaterialModule } from "src/app/modules/material/material.module";
import { SharedModule } from "src/app/modules/shared/shared.module";

// Reducers
import * as fromAuth from "src/app/modules/auth/store/auth.reducer";

@NgModule({
  declarations: [LoginComponent],
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    SharedModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
  ],
  exports: [LoginComponent]
})
export class AuthModule {}
