import { createFeatureSelector, createSelector } from "@ngrx/store";

// Reducers
import {
  bunnyFeatureKey,
  BunnyState,
} from "src/app/modules/bunny-cdn/store/reducers/bunny.reducer";

export const selectBunnyState =
  createFeatureSelector<BunnyState>(bunnyFeatureKey);

export const selectIsBunnyCDNLoaded = createSelector(selectBunnyState, (bunny: BunnyState) => {
  return {
    isLoading: bunny.isLoading,
    isLoaded: bunny.isLoaded,
  };
});
