<div class="container">
  <div class="bunny">
    <div class="bunny--header">
      <h2>Bunny CDN</h2>
    </div>

    <form
      #formGroupDirective
      class="bunny--form"
      [formGroup]="bunnyCDNForm"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="bunny--group">
        <mat-form-field>
          <mat-label>URL</mat-label>
          <textarea
            matInput
            placeholder="URL"
            formControlName="url"
          ></textarea>
        </mat-form-field>

        <ng-container
          *ngTemplateOutlet="urlErrorTemplate"
        ></ng-container>

        <div class="bunny--actions">
          <button
            mat-raised-button
            color="primary"
            [disabled]="bunnyCDNForm.invalid || isLoading"
          >
            <div class="button--content">
              Purge Image
              <ng-container *ngIf="isLoading">
                <mat-spinner [diameter]="20"></mat-spinner>
              </ng-container>
            </div>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-container *ngIf="url && isLoaded">
  <div class="image">
    <div class="image--header">
      <h2>Preview</h2>
    </div>
    <div class="image--content">
      <img [src]="url" alt="Image" />
    </div>
  </div>
</ng-container>

<ng-template #urlErrorTemplate>
  <div
    class="errors"
    *ngIf="
        bunnyCDNForm.controls['url'].errors
          && 
        bunnyCDNForm.controls['url'].touched
      "
  >
    <ng-container>
      <div
        [hidden]="!bunnyCDNForm.controls['url'].errors['required']"
      >
        URL is required
      </div>
    </ng-container>
  </div>
</ng-template>
