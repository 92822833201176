<mat-toolbar color="primary">
  <div class="header">
    <h1>{{title}}</h1>

    <ng-container *ngIf="isLoginSuccess">
      <div class="buttons">
        <button
          mat-raised-button
          color="accent"
          (click)="onLogout()"
        >
          Logout
        </button>
      </div>
    </ng-container>
  </div>
</mat-toolbar>
