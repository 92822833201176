<div class="container">
  <div class="login">
    <div class="login--header">
      <h2>Login</h2>
    </div>

    <form
      class="login--form"
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="login--group">
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input
            matInput
            role="presentation"
            autocomplete="new-username"
            aria-autocomplete="none"
            placeholder="Username"
            autocomplete="false"
            formControlName="username"
            value=""
          />
        </mat-form-field>

        <ng-container
          *ngTemplateOutlet="usernameErrorTemplate"
        ></ng-container>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            placeholder="Password"
            autocomplete="false"
            formControlName="password"
            value=""
          />
        </mat-form-field>

        <ng-container
          *ngTemplateOutlet="passwordErrorTemplate"
        ></ng-container>
      </div>

      <div class="login--actions">
        <button
          mat-raised-button
          color="primary"
          [disabled]="loginForm.invalid"
        >
          Primary
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #usernameErrorTemplate>
  <div
    class="errors"
    *ngIf="
      loginForm.controls['username'].errors
        && 
      loginForm.controls['username'].touched
    "
  >
    <ng-container>
      <div
        [hidden]="!loginForm.controls['username'].errors['required']"
      >
        Username is required
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #passwordErrorTemplate>
  <div
    class="errors"
    *ngIf="
      loginForm.controls['password'].errors
        && 
      loginForm.controls['password'].touched
    "
  >
    <ng-container>
      <div
        [hidden]="!loginForm.controls['password'].errors['required']"
      >
        Password is required
      </div>
    </ng-container>
  </div>
</ng-template>
