import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

// Components
import { BunnyCDNComponent } from "src/app/modules/bunny-cdn/components/bunny-cdn/bunny-cdn.component";
import { LoginComponent } from "src/app/modules/auth/components/login/login.component";

// Guards
import { ProtectGuard } from "src/app/modules/auth/guards/protect.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent },
  {
    path: "bunny-cdn",
    component: BunnyCDNComponent, canActivate: [ProtectGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
