export const environment = {
    production: true,
    // Credentials
    credentials: {
        username: "gabrielle.tornberg@blixxgaming.com",
        password: "Test-123"
    },
    // Bunny CDN
    bunnyURL: "https://api.bunny.net/purge",
    bunnyCDNAccessToken: "4c65a485-fce9-4eef-9b24-9f34101fe2d12904c60a-bc1a-43e6-b29b-a51fc0ca9d02"
};
