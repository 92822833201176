import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Actions
import { bunnyRequested } from "src/app/modules/bunny-cdn/store/actions/bunny.actions";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectIsBunnyCDNLoaded } from "src/app/modules/bunny-cdn/store/selectors/bunny.selectors";

@Component({
  selector: "app-bunny-cdn",
  templateUrl: "./bunny-cdn.component.html",
  styleUrls: ["./bunny-cdn.component.scss"],
})
export class BunnyCDNComponent {
  // Strings
  url: string = "";

  // Booleans
  isLoading: boolean = false;
  isLoaded: boolean = false;

  // Forms
  bunnyCDNForm: FormGroup;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(formBuilder: FormBuilder, private store: Store<AppState>) {
    this.bunnyCDNForm = formBuilder.group({
      url: ["", [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.store
        .select(selectIsBunnyCDNLoaded)
        .subscribe(({ isLoading, isLoaded }) => {
          this.isLoading = isLoading;

          this.isLoaded = isLoaded;

          if (isLoaded) {
            this.bunnyCDNForm.patchValue({
              url: "",
            });

            this.bunnyCDNForm.markAsPristine();

            this.bunnyCDNForm.markAsUntouched();

            this.bunnyCDNForm.updateValueAndValidity();
          }
        }),
    ];
  }

  onSubmit(): void {
    this.url = this.bunnyCDNForm.value.url.trim();

    this.isLoaded = false;

    this.isLoading = true;

    this.store.dispatch(bunnyRequested({ url: this.url }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
