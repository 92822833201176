import { Action, createReducer, on } from "@ngrx/store";

// Actions
import { bunnyFailure, bunnyRequested, bunnySuccess } from "src/app/modules/bunny-cdn/store/actions/bunny.actions";

export const bunnyFeatureKey = "bunny";

export interface BunnyState {
  isLoading: boolean;
  isLoaded: boolean;
}

export const initialState: BunnyState = {
  isLoading: false,
  isLoaded: false,
};

const bunnyReducer = createReducer(
  initialState,
  on(bunnyRequested, (state: BunnyState) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  // Login page
  on(bunnySuccess, (state: BunnyState) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
  })),
  // Signup page
  on(bunnyFailure, (state: BunnyState) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
  }))
);

export function reducer(state: BunnyState | undefined, action: Action) {
  return bunnyReducer(state, action);
}
