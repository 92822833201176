import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";

// Actions
import { login } from "src/app/modules/auth/store/auth.actions";

// Reducers
import { AppState } from "src/app/store/reducers";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  // Forms
  loginForm: FormGroup;

  constructor(formBuilder: FormBuilder, private store: Store<AppState>) {
    this.loginForm = formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]]
    });
  }

  onSubmit(): void {
    this.store.dispatch(login({ credentials: this.loginForm.value }));
  }
}
