import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";

// Actions
import {
  logoutSuccess,
  loginSuccess,
  loginFailed,
  logout,
  login,
} from "src/app/modules/auth/store/auth.actions";

// Models
import { RawLoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { Login } from "src/app/modules/auth/models/login.model";

// Services
import { LoginService } from "src/app/modules/auth/services/login.service";

@Injectable()
export class AuthEffects {
  constructor(
    private loginService: LoginService,
    private actions$: Actions,
    private router: Router
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      map((x) => x.credentials),
      mergeMap((credentials: RawLoginCredentials) => {
        return this.loginService.onGetObservableLogin(credentials).pipe(
          tap((isLoggedIn) => {
            if (isLoggedIn) {
              this.router.navigate(["/bunny-cdn"]);
            }
          }),
          map((isLoggedIn: boolean) => {
            const login: Login = {
              username: credentials.username,
              isLoginSuccess: isLoggedIn,
            };

            localStorage.setItem("bunny_cdn_isLoggedIn", JSON.stringify(login));

            return loginSuccess({
              username: credentials.username,
              isLoginSuccess: isLoggedIn,
            });
          }),
          catchError((response: HttpErrorResponse) => {
            return of(
              loginFailed({
                errorMessage: response.error.error.reason,
              })
            );
          })
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => {
        localStorage.removeItem("bunny_cdn_isLoggedIn");

        this.router.navigate(["/login"]);
      }),
      map(() => {
        return logoutSuccess();
      })
    )
  );
}
