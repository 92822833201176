import { Action, createReducer, on } from "@ngrx/store";

// Actions
import {
  logoutSuccess,
  loginSuccess,
  login,
} from "src/app/modules/auth/store/auth.actions";

export const authFeatureKey = "auth";

export interface AuthState {
  isLoading: boolean;
  error: { [page: string]: string };
  username?: string;
  timerRetry?: number;
  isLoginSuccess?: boolean;
}

export const initialState: AuthState = {
  isLoading: false,
  error: {},
  username: undefined,
  timerRetry: undefined,
  isLoginSuccess: false,
};

const authReducer = createReducer(
  initialState,
  on(login, (state: AuthState) => ({
    ...state,
    isLoading: true,
    error: {},
  })),
  // Login page
  on(
    loginSuccess,
    (
      state: AuthState,
      action: { username: string; isLoginSuccess: boolean }
    ) => ({
      ...state,
      isLoading: false,
      username: action.username,
      isLoginSuccess: action.isLoginSuccess,
    })
  ),
  // Signup page
  on(logoutSuccess, (state: AuthState) => ({
    ...state,
    isLoading: false,
    username: undefined,
    permissions: undefined,
    jwtToken: undefined,
    isLoginSuccess: false
  }))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
