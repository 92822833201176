import { Observable, catchError, map, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Models
import { BunnyCDNResponse } from "../models/bunny-cdn.response";

@Injectable({
  providedIn: "root",
})
export class BunnyCDNService {
  constructor(private httpClient: HttpClient) {}

  onGetObservablePurgeImage(imageURL: string): Observable<boolean> {
    console.log('imageURL', imageURL)
    return this.httpClient
      .post<BunnyCDNResponse>(
        `${environment.bunnyURL}?url=${imageURL}&async=false&access_token=${environment.bunnyCDNAccessToken}`,
        {},
        {
          headers: {
            AccessKey: `${environment.bunnyCDNAccessToken}`,
          },
        }
      )
      .pipe(
        map(() => {
          return true;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
