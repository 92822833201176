import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  UrlTree,
  Router,
} from "@angular/router";

// Environments
import { environment } from "src/environments/environment";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { isLoggedSelector } from "src/app/modules/auth/store/auth.selector";

@Injectable({
  providedIn: "root",
})
export class ProtectGuard implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(isLoggedSelector).pipe(
      map(({ isLoginSuccess, username }) => {
        console.log(isLoginSuccess, username);
        if (isLoginSuccess && username === environment.credentials.username) {
          return true;
        } else {
          this.router.navigate(["/login"]);

          return false;
        }
      })
    );
  }
}
