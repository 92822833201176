import { createAction, props } from "@ngrx/store";

export enum BunnyActionTypes {
  BunnyRequested = "[Bunny Page] Bunny Requested",
  BunnySuccess = "[Bunny API] Bunny Success",
  BunnyFailure = "[Bunny API] Bunny Failure",
}

// Login
export const bunnyRequested = createAction(
  BunnyActionTypes.BunnyRequested,
  props<{ url: string }>()
);
export const bunnySuccess = createAction(BunnyActionTypes.BunnySuccess);
export const bunnyFailure = createAction(
  BunnyActionTypes.BunnyFailure,
  props<{ errorMessage: string }>()
);
