import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";

// Actions
import {
  bunnyFailure,
  bunnyRequested,
  bunnySuccess,
} from "src/app/modules/bunny-cdn/store/actions/bunny.actions";

// Components
import { SnackBarComponent } from "src/app/modules/shared/components/snack-bar/snack-bar.component";

// Services
import { BunnyCDNService } from "src/app/modules/bunny-cdn/services/bunny-cdn.service";

@Injectable()
export class BunnyEffects {
  constructor(
    private bunnyCDNService: BunnyCDNService,
    private snackBar: MatSnackBar,
    private actions$: Actions
  ) {}

  bunnyRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bunnyRequested),
      map((x) => x.url),
      mergeMap((url: string) => {
        return this.bunnyCDNService.onGetObservablePurgeImage(url).pipe(
          map(() => bunnySuccess()),
          catchError((response: HttpErrorResponse) => {
            return of(
              bunnyFailure({
                errorMessage: "error",
              })
            );
          })
        );
      })
    )
  );

  bunnySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(bunnySuccess),
        tap(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {
            data: { message: "Successful" },
            duration: 2 * 500,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        })
      ),
    { dispatch: false }
  );
}
