import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Models
import { RawLoginCredentials } from "src/app/modules/auth/models/login-credentials.model";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor() {}

  onGetObservableLogin(credentials: RawLoginCredentials): Observable<boolean> {
    return new Observable((observer) => {
      if (
        credentials.username == environment.credentials.username &&
        credentials.password == environment.credentials.password
      ) {
        observer.next(true);
      } else {
        observer.next(false);
      }
    });
  }
}
