import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

// Reducers
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "iot-snack-bar",
  templateUrl: "./snack-bar.component.html",
  styleUrls: ["./snack-bar.component.scss"],
})
export class SnackBarComponent implements OnInit {
  constructor(
    private snackBarRef: MatSnackBarRef<SnackBarComponent>,
    private router: Router,
    private store: Store<AppState>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {}
  ) {}

  ngOnInit() {}

  close() {
    this.snackBarRef.dismiss();
  }

  onOpen() {}
}
