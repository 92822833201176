import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Components
import { AppComponent } from "src/app/app.component";

// Environments
import { environment } from "src/environments/environment";

// Interceptors
import { AuthenticationInterceptor } from "src/app/modules/auth/interceptors/authentication.interceptor";

// Modules
import { BunnyCDNModule } from "src/app/modules/bunny-cdn/bunny-cdn.module";
import { MaterialModule } from "src/app/modules/material/material.module";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { AuthModule } from "src/app/modules/auth/auth.module";

// Reducers
import { metaReducers, reducerProvider, REDUCERS_TOKEN } from "src/app/store/reducers";

// Routings
import { AppRoutingModule } from "src/app/app-routing.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    BunnyCDNModule,
    BrowserModule,
    SharedModule,
    CommonModule,
    AuthModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(REDUCERS_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    reducerProvider,
   /* {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },*/
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
