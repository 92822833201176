import { Component } from "@angular/core";
import { Login } from "./modules/auth/models/login.model";
import { Store } from "@ngrx/store";
import { AppState } from "./store/reducers";
import { loginSuccess } from "./modules/auth/store/auth.actions";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "bunny-cdn";

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    let value: string | null = localStorage.getItem("bunny_cdn_isLoggedIn");

    if (value) {
      const login: Login = JSON.parse(value);

      this.store.dispatch(loginSuccess(login));

      if (login.isLoginSuccess && login.username === environment.credentials.username) {
        this.router.navigate(["/bunny-cdn"]);
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
