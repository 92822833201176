<div class="snackbar cmp-snack-bar" (click)="onOpen()">
  <div class="container">
    <mat-icon class="icon">info</mat-icon>

    <span class="cmp-snack-bar-el-message">
      The image has been purged successfully
    </span>
  </div>

  <button
    type="button"
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
