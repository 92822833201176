import { Component, Input, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { logout } from "src/app/modules/auth/store/auth.actions";
import { isLoggedInSelector } from "src/app/modules/auth/store/auth.selector";
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
  // Inputs
  @Input() inputTitle: string = "";

  // Strings
  title: string = "";

  // Booleans
  isLoginSuccess: boolean = false;
  isHomeEnabled: boolean = false;
  isLoading: boolean = false;

  constructor(private store: Store<AppState>, private router: Router) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["inputTitle"] &&
      changes["inputTitle"].previousValue != changes["inputTitle"].currentValue
    ) {
      this.title = changes["inputTitle"].currentValue;
    }
  }

  ngOnInit(): void {
    this.store.select(isLoggedInSelector).subscribe((isLoginSuccess) => {
      this.isLoginSuccess = isLoginSuccess;
    });
  }

  onLogout(): void {
    this.router.navigate(["/login"]);

    this.store.dispatch(logout());
  }
}
