import { createFeatureSelector, createSelector } from "@ngrx/store";

// Reducers
import { AuthState, authFeatureKey } from "src/app/modules/auth/store/auth.reducer";

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const isLoggedInSelector = createSelector(
  selectAuthState,
  (auth) => !!auth.isLoginSuccess
);

export const isLoggedSelector = createSelector(
  selectAuthState,
  (auth) => {
    return {
      isLoginSuccess: auth.isLoginSuccess,
      username: auth.username
    }
  }
);

export const isLoggedOut = createSelector(isLoggedInSelector, (loggedIn) => !loggedIn);
