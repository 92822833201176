import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { NgModule } from "@angular/core";

// Components
import { BunnyCDNComponent } from "src/app/modules/bunny-cdn/components/bunny-cdn/bunny-cdn.component";

// Effects
import { BunnyEffects } from "src/app/modules/bunny-cdn/store/effects/bunny.effects";

// Modules
import { MaterialModule } from "src/app/modules/material/material.module";
import { StoreModule } from "@ngrx/store";

import * as fromBunny from "src/app/modules/bunny-cdn/store/reducers/bunny.reducer";

@NgModule({
  declarations: [BunnyCDNComponent],
  imports: [
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    StoreModule.forFeature(fromBunny.bunnyFeatureKey, fromBunny.reducer),
    EffectsModule.forFeature([BunnyEffects]),
  ],
  exports: [BunnyCDNComponent],
})
export class BunnyCDNModule {}
